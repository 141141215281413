<template>
  <BrToolBar @search="buscar" :configFilter="configFilter" :addlFilter="addlFilter">
    <!-- <BrModalCadastro /> -->
    <template v-slot:selectTypePagamentos>
      <slot name="select"/>
    </template>
  </BrToolBar>
</template>

<script>
/**
 * MIXIN
 */
import toolbar from '@/views/mixins/toolbar'

export default {
  name: `BrToolBarCadastroRole`,
  mixins: [toolbar],
  components: { 
    // BrModalCadastro: () => import('./modalCadastro'),
    BrToolBar: () => import('@/views/components/toolbar')
  }
}
</script>